import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import {APP_API_V2, REST_API} from '@/utils/constants'

import {getInstance} from '@/auth'
import axiosInstance from '@/utils/axios'

export class UserService {
    constructor() {
        this.oldClientUrl = `${REST_API}/v1/api/clients`
        this.clientUrl = `${APP_API_V2}/clients`
        this.usersUrl = `${APP_API_V2}/users`
        this.staffUrl = `${APP_API_V2}/staff`
        this.workshopsUrl = `${APP_API_V2}/workshops`
        this.userPointsUrl = `${APP_API_V2}/user-points`
    }

    async getUser (userId) {
        const { data: user } = await axiosInstance.get(
            `${this.usersUrl}/by-id/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        const newUser = camelcaseKeys(user, {deep: true})
        const numberParts = newUser.phone ? newUser.phone.split(' ') : null
        newUser.phone = {
            countryCode: 'AU',
            phone: ''
        }
        if (numberParts) {
            newUser.phone = {
                ...newUser.phone,
                ...{countryCode: numberParts[0], phone: numberParts.slice(1).join(' ')}
            }
        }
        return newUser
    }

    async getUserByClientId(clientId) {
        const {data: user} = await axiosInstance.get(`${this.usersUrl}/${clientId}/by-client-id`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(user)
    }

    async getUsers() {
        const { data: users } = await axiosInstance.get(`${this.usersUrl}`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(users, { deep: true })
    }

    async addPoints(clientId, data) {
        const {data: userPoints} = await axiosInstance.post(
            `${this.usersUrl}/${clientId}/user-points`,
            snakecaseKeys(data)
        )
        return camelcaseKeys(userPoints)
    }

    async addPointsForAssessment(clientId, form_type, data) {
        const { data: userPoints } = await axiosInstance.post(
            `${this.usersUrl}/${clientId}/user-points/assessments/${form_type}`,
            snakecaseKeys(data)
        )
        return camelcaseKeys(userPoints)
    }

    async updatePoints(clientId, data) {
        const {userPointId, ...rest} = data
        const {data: userPoints} = await axiosInstance.patch(
            `${this.usersUrl}/${clientId}/user-points/${userPointId}`,
            snakecaseKeys(rest),
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(userPoints)
    }

    async getPointsForUser(clientId) {
        const {data: userPoints} = await axiosInstance.get(
            `${this.usersUrl}/${clientId}/user-points`,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(userPoints)
    }

    async deletePointsForUser(clientId, userPointId) {
        const {data: userPoints} = await axiosInstance.delete(
            `${this.usersUrl}/${clientId}/user-points/${userPointId}`,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(userPoints)
    }

    async getStaffUsers() {
        const {data: users} = await axiosInstance.get(`${this.staffUrl}`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(users, {deep: true})
    }

    // These are the list of Young people that ah
    async getClientNames() {
        const {data: clientNames} = await axiosInstance.get(`${this.usersUrl}/client-names`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(clientNames, {deep: true})
    }

    async updateUserByClientId(clientId, data) {
        const {data: user} = await axiosInstance.patch(
            `${this.usersUrl}/${clientId}`,
            snakecaseKeys(data),
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(user)
    }

    async inviteUser(clientId) {
        const {data: user} = await axiosInstance.post(
            `${this.oldClientUrl}/${clientId}/invite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(user)
    }

    async reinviteUser(clientId) {
        const {data: user} = await axiosInstance.post(
            `${this.oldClientUrl}/${clientId}/reinvite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(user)
    }

    async updateUserStatus(auth0Id) {
        try {
            const { data } = await axiosInstance.post(
                `${this.usersUrl}/${auth0Id}/status`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${getInstance().token}`
                    }
                }
            )
            return camelcaseKeys(data)
        } catch (error) {
            console.error('Error updating user status', error)
            return null
        }
    }
}

export const userService = new UserService()

<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Close Program</h1>
        <v-form
            ref="closeEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center">
                This changes the episode status to <span class="text-red font-bold">Program Closed</span> and
                <span class="block mb-2">lock all forms &amp; records (excludes case notes) for this episode.
                </span>
                <p class="mb-2">Are you sure you would like to close the program?</p>
            </div>
            <div class="grid grid-cols-2 gap-x-4 mb-0 w-full">
                <div class="col-span-1">
                    <EditableDatePicker
                        v-model="date"
                        :label="'Program End Date'"
                        disableFutureDate
                        min="2010-01-01"
                        :rules="[required, endDateValidate]"
                        @changed="changedDateEnd"
                    />
                </div>
                <div class="col-span-1">
                    <!--This Outcome field should update the one in programDetailsForm-->
                    <v-select
                        v-model="closeEpisodeForm.outcome"
                        label="Outcome"
                        :items="outcomes"
                        :rules="[required]"
                    ></v-select>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-x-4 mb-0 w-full">
                <div class="col-span-1">
                    <!--This Outcome field should update the one in programDetailsForm-->
                    <v-select
                        v-model="closeEpisodeForm.reason"
                        label="Reason"
                        :items="reasons"
                        :rules="[required]"
                    ></v-select>
                </div>
                <div class="col-span-1">
                    <!--This Outcome field should update the one in programDetailsForm-->
                    <v-select
                        v-model="closeEpisodeForm.progress"
                        label="Progress"
                        :items="progressOptions"
                        :rules="[required]"
                    ></v-select>
                </div>
                <div
                    class="col-span-1"
                >
                <!--removing
                    v-if="showService" due to client feedback -->
                    <v-select
                        v-model="closeEpisodeForm.service"
                        label="Referral to Another Service"
                        :items="referralServices"
                        :rules="[required]"
                    ></v-select>
                </div>
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-40"
                >Yes, Close Program</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { parse, format } from 'date-fns'


export default {
    name: 'ModalCloseEpisode',
    components: { EditableDatePicker },
    mixins: [ MessageDialog, FormRules ],
    data() {
        return {
            defaultCloseEpisode: { service: 'NO_REFERRAL' },
            isValid: true,
            date: null,
            endDate: ''
        }
    },
    mounted() {
        this.date = this.defaultCloseEpisode.endDate = format(new Date(), 'yyyy-MM-dd')
    },
    methods: {
        validate() {
            return this.$refs.closeEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
            this.dateEnd = ''
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.closeEpisodeForm.status = 'CLOSED'
                this.data.episode.endDate = this.endDate
                this.$root.$emit('submit-close-episode', {
                    messageText: 'Episode status has been set to PROGRAM CLOSED',
                    episode: { ...this.data.episode, ...this.closeEpisodeForm, service: this.closeEpisodeForm.service }
                })
                this.closeModal()
            }
        },
        endDateValidate(v) {
            if (!v || !this.episode || !this.episode.startDate) return true

            const endDate = format(parse(v, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd', new Date())
            if (endDate >= this.episode.startDate) return true

            return 'End date should be greater than start date'
        },
        changedDateEnd(value) {
            this.endDate = value
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        outcomes() {
            return this.staticChoices['programDetail.outcome'] || []
        },
        referralServices() {
            return this.staticChoices['programDetail.referralService'] || []
        },
        reasons() {
            return this.staticChoices['programDetail.reason'] || []
        },
        progressOptions() {
            return this.staticChoices['programDetail.progress'] || []
        },
        episode() {
            return this.data?.episode
        },
        closeEpisodeForm: {
            get() {
                return this.defaultCloseEpisode
            },
            set(closeEpisodeForm) {
                console.log(closeEpisodeForm)
            }
        }
    },
    watch: {
        date() {
            this.closeEpisodeForm.endDate = this.date
        },
        closeEpisodeForm() {
            this.date = this.closeEpisodeForm.endDate
        }
    }
}
</script>

import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2, REST_API } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class ClientService {
    constructor() {
        this.clientsUrl = `${APP_API_V2}/clients`
    }

    async getClient(clientId) {
        const { data } = await axiosInstance.get(`${this.clientsUrl}/${clientId}`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(data, { deep: true })
    }

    async getClients() {
        const { data } = await axiosInstance.get(`${this.clientsUrl}/young-people/all`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        let clients = camelcaseKeys(data || [], { deep: true })
        return clients.filter((client) => client.firstName !== '')
    }

    async searchClients(searchTerm, offset) {
        let url = `${this.clientsUrl}?q=${searchTerm}`
        if (offset !== null && offset !== undefined) {
            url += `&page=${offset}`
        }
        const { data } = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        const { page, pages, items } = data
        return { clients: camelcaseKeys(items), page, pages }
    }

    async fullSearchClients(searchTerm, offset, pageSize) {
        let url = `${this.clientsUrl}/young-people/search?q=${searchTerm}`
        if (offset !== null && offset !== undefined && offset > 0) {
            url += `&page=${offset}`
        }
        if (pageSize !== null && pageSize !== undefined) {
            url += `&page_size=${pageSize}`
        }
        const { data } = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        const { page, pages, items } = data
        return { clients: camelcaseKeys(items), page, pages }
    }
}

export const clientService = new ClientService()

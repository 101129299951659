<template>
    <div class="">
        <div class="w-full">
            <h4 class="font-display font-semibold text-left text-xl">Permissions</h4>
        </div>

        <div class="flex items-center w-full">
            <PermissionTable
                class="w-full"
                :table-data="userLocationPrograms"
                :location-programs="locationPrograms"
                :locations="computedLocations"
                :programs="programs"
                :is-loaded="isFinishedLoading"
                :readOnly="readOnly"
                @table-data-change="locationProgramsChangeHandler"
            />
        </div>
    </div>
</template>

<script>
import { onMounted, toRefs, reactive } from '@vue/composition-api'
import GET_PROGRAMS from '@/graphql/queries/getPrograms.gql'
import GET_LOCATION_PROGRAMS from '@/graphql/queries/getLocationPrograms.gql'
import camelcaseKeys from 'camelcase-keys'
import PermissionTable from '@/components/user-management/partials/permissions/PermissionTable'
import {getInstance} from '@/auth'
import { locationService } from '@/services/location.service'

export default {
    name: 'Permissions',
    components: {PermissionTable},
    props: {
        value: {
            type: Array,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            programs: [],
            locationPrograms: [],
            isFinishedLoading: false
        }
    },
    apollo: {
        programs: {
            query: GET_PROGRAMS,
            result({data: {programs}}) {
                this.programs = camelcaseKeys(programs || [], {deep: true})
                // console.log('programs', this.programs)
            }
        },
        locationPrograms: {
            query: GET_LOCATION_PROGRAMS,
            result({data: {locationPrograms}}) {
                this.locationPrograms = camelcaseKeys(locationPrograms || [], {deep: true})
            }
        }
    },
    methods: {
        containsProgram(program) {
            const filtered =
                this.computedLocationPrograms.find(
                    clp => clp.program.programId === program.programId
                ) != null
            return filtered
        },
        locationProgramsChangeHandler(locationPrograms) {
            locationPrograms = locationPrograms.reduce((arr, {locationId, programs}) => {
                programs.forEach(programId => {
                    const locationProgramId = this.locationPrograms.find(
                        ({location, program}) =>
                            location.locationId == locationId && program.programId == programId
                    )?.locationProgramId

                    if (!locationProgramId) return

                    arr.push({
                        locationProgramId,
                        locationId,
                        programId
                    })
                })
                return arr
            }, [])
            this.$emit('input', locationPrograms)
        }
    },
    computed: {
        computedLocationPrograms() {
            const filtered = this.locationPrograms.filter(lp => {
                return (
                    this.selectedLocation != null &&
                    lp.location &&
                    lp.location.locationId === this.selectedLocation
                )
            })
            return filtered
        },
        computedLocations() {
            return this.locations.map(location => {
                return {
                    value: location.locationId,
                    text: location.name
                }
            })
        },
        userLocationPrograms() {
            return this.value.reduce((arr, {locationId, programId}) => {
                const idx = arr.findIndex(a => a.locationId === locationId)
                if (idx === -1) {
                    arr.push({
                        id: arr.length + 1,
                        locationId,
                        programs: [programId]
                    })
                } else {
                    arr[idx].programs.push(programId)
                }
                return arr
            }, [])
        }
    },
    setup() {
        const state = reactive({
            selectedLocation: null,
            locations: [],
            loading: true,
        })

        onMounted(async () => {
            try {
                const locations = await locationService.getLocations()
                state.locations = [...locations]
            } catch (error) {
                console.error(error)
            } finally {
                state.loading = false
            }

        })

        return {
            ...toRefs(state),
        }
    }
}
</script>
